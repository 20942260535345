import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home/homePage',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/index.vue'),
    children: [
      {
        path: '/home/homePage',
        name: 'homePage',
        component: () => import('../views/homePage/index.vue'),
      },
      {
        path: '/home/aboutZhisheng',
        name: 'aboutZhisheng',
        component: () => import('../views/aboutZhisheng/index.vue')
      },
      {
        path: '/home/products',
        name: 'products',
        component: () => import('../views/products/index.vue')
      },
      {
        path: '/home/newsInformation',
        name: 'newsInformation',
        component: () => import('../views/newsInformation/index.vue')
      },
      {
        path: '/home/contactUs',
        name: 'contactUs',
        component: () => import('../views/contactUs/index.vue')
      },
      {
        path: '/home/multivariateSolution',
        name: 'multivariateSolution',
        component: () => import('../views/multivariateSolution/index.vue')
      },
      {
        path: '/home/electronicSigning',
        name: 'electronicSigning',
        component: () => import('../views/electronicSigning/index.vue')
      },
      {
        path: '/home/evidence',
        name: 'evidence',
        component: () => import('../views/evidence/index.vue')
      },
      {
        path: '/home/mediate',
        name: 'mediate',
        component: () => import('../views/mediate/index.vue')
      },
      {
        path: '/home/contact',
        name: 'contact',
        component: () => import('../views/contact/index.vue')
      },

      
    ]  
  }, 
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
